@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500;600&display=swap');

/* 
	Variables CSS Section
	Public Section
	Navbar Section
	Show Menu Section
	Home Section
	Buttons Section
	About Section
	Skills Section
	Percentage bar Section
	Qualification Section
	Services Section
	Active Modal
	Portfolio Section
	Project Section
	Certificates Section
	Contact Section
	Footer Section
	Scroll Up Section
	Scroll Bar Section
	ProgressBar Section
	Media Queries Section
		Small devices
		Medium devices
		Large devices
*/


/* Variables CSS Section */
:root {
	--header-heifht: 3rem;

	/* Color Section */
	--hue-color: 214;/* purple 250 - Green 142 - Blue 230 - Pink 340  l-blue 214*/

	/* HSL Color mode */
	/* https://www.w3schools.com/colors/colors_hsl.asp */
	--first-color: hsl(var(--hue-color), 69%, 61%);
    --first-color-second: hsl(var(--hue-color), 69%, 61%);
    --first-color-alt: hsl(var(--hue-color), 57%, 57%);
    --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
    --title-color: hsl(var(--hue-color), 8%, 15%);
    --text-color: hsl(var(--hue-color), 8%, 45%);
    --text-color-light: hsl(var(--hue-color), 8%, 65%);
    --input-color: hsl(var(--hue-color), 70%, 96%);
    --body-color: hsl(var(--hue-color), 60%, 99%);
    --container-color: #fff;
    --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);
	--progress-color: hsl(var(--hue-color), 69%, 61%);

	/* Font and typeography */
	--body-font: 'Kanit', sans-serif;
	/* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
	--big-font-size: 2rem;
	--h1-font-size: 1.5rem;
	--h2-font-size: 1.25rem;
	--h3-font-size: 1.125rem;
	--normal-font-size: .938rem;
	--small-font-size: .813rem;
	--smaller-font-size: .75rem;

	/* Font weight */
	--font-medium: 500;
    --font-semi-bold: 600;

	/* Margenes Bottom */
	/* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
	--mb-0-25: .25rem;
	--mb-0-5: .5rem;
	--mb-0-75: .75rem;
	--mb-1: 1rem;
	--mb-1-5: 1.5rem;
	--mb-2: 2rem;
	--mb-2-5: 2.5rem;
	--mb-3: 3rem;

	/* z index */
	--z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
}
/* Font size for large device */
@media screen and (min-width: 968px) {
	:root {
		--big-font-size: 3rem;
		--h1-font-size: 2.25rem;
		--h2-font-size: 1.5rem;
		--h3-font-size: 1.25rem;
		--normal-font-size: 1rem;
		--small-font-size: .875rem;
		--smaller-font-size: .813rem;
	}
}

/* Dark theme variable Section*/
.logo{
	max-width: 100% !important;
	width: 100px !important;
	height: auto !important;
}

body.dark-theme{
	/* HSL Color mode */
	/* https://www.w3schools.com/colors/colors_hsl.asp */
    --first-color-second: hsl(var(--hue-color), 30%, 8%);
    --title-color: hsl(var(--hue-color), 8%, 95%);
    --text-color: hsl(var(--hue-color), 8%, 75%);
    --input-color: hsl(var(--hue-color), 29%, 16%);
    --body-color: hsl(var(--hue-color), 28%, 12%);
    --container-color: hsl(var(--hue-color), 29%, 16%);
	--scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
	--progress-color: hsl(var(--hue-color), 30%, 8%);
}

/* Button change theme Section */
.nav_btns{
	display: flex;
	align-items: center;
}

.change-theme{
	font-size: 1.25rem;
	color: var(--title-color);
	margin-right: var(--mb-1);
	cursor: pointer;
}

.change-theme:hover{
	color: var(--first-color);
}

/* Public Section */
*{
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html{
	scroll-behavior: smooth;
}

body{
	margin: 0 0 var(--header-heifht) 0;
	font-family: var(--body-font);
	font-size: var(--normal-font-size);
	background-color: var(--body-color);
	color: var(--text-color);
}

h1,h2,h3,h4{
	color: var(--title-color);
	font-weight: var(--font-semi-bold);
}

ul{
	list-style: none;
}

a{
	text-decoration: none;
}

img{
	max-width: 100%;
	height: auto;
}

/* Reuse Class Section */
.section{
	padding: 2rem 0 4rem;
}

.section_title{
	font-size: var(--h1-font-size);
}

.section_subtitle{
	display: block;
	font-size: var(--small-font-size);
	margin-bottom: var(--mb-3);
}

.section_title,
.section_subtitle{
	text-align: center;
}

/* Layout Section */
.container{
	max-width: 768px;
	margin-left: var(--mb-1-5);
	margin-right: var(--mb-1-5);
}

.grid{
	display: grid;
	gap: 1.5rem;
}

.header{
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: var(--z-fixed);
	background-color: var(--body-color);
}


/* Navbar Section */
.nav{
	max-width: 968px;
	height: var(--header-height);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.nav_logo,
.nav_toggle{
	color: var(--title-color);
	font-weight: var(--font-medium);
}

.nav_logo:hover{
	color: var(--first-color);
}

.nav_toggle{
	font-size: 1.1rem;
	cursor: pointer;
}

.nav_toggle:hover{
	color: var(--first-color);
}

@media screen and (max-width: 767px){
	.nav_menu{
		position: fixed;
		bottom: -100%;
		left: 0;
		width: 100%;
		background-color: var(--body-color);
		padding: 2rem 1.5rem 4rem;
		box-shadow: 0 -1px 4px rgba(0,0,0,.15);
		border-radius: 1.5rem 1.5rem 0 0;
		transition: .3s;
	}
}

.nav_list{
	grid-template-columns: repeat(3, 1fr);
	gap: 2rem;
}

.nav_link{
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: var(--small-font-size);
	color: var(--title-color);
	font-weight: var(--font-medium);
}

.nav_link:hover{
	color: var(--first-color);
}

.nav_icon{
	font-size: 1.2rem;
}

.nav_close{
	position: absolute;
	right: 1.3rem;
	bottom: .5rem;
	font-size: 1.5rem;
	cursor: pointer;
	color: var(--first-color);
}

.nav_close:hover{
	color: var(--first-color);
}

/* Show Menu Section */
.show-menu{
	bottom: 0;
}

/* Active link Section */
.active-link{
	color: var(--first-color);
}

/* change background header */
.scroll-header{
	box-shadow: 0 -1px 4px rgba(0,0,0,.15);
}

/* Home Section */

.home_container{
	gap: 1rem;
}

.home_content{
	grid-template-columns: .5fr 3fr;
	padding-top: 3.5rem;
	align-items: center;
}

.home_social{
	display: grid;
	grid-template-columns: max-content;
	row-gap: 1rem;
}

.home_social-icon{
	font-style: 1.25rem;
	color: var(--first-color);
}

.home_social-icon:hover{
	color: var(--first-color-alt);
}

.home_blob{
	width: 200px;
	fill: var(--first-color);
}
.home_blob-img{
	width: 270px;
}


.home_data{
	grid-column: 1/3;
}

.home_title{
	font-size: var(--big-font-size);
}

.home_subtitle{
	font-size: var(--h3-font-size);
	color: var(--text-color);
	font-weight: var(--font-medium);
	margin-bottom: var(--mb-0-75);
}

.home_description{
	margin-bottom: var(--mb-2);
}

.home_scroll{
	display: none;
}

.home_scroll-button{
	color: var(--first-color);
	transition: .3s;
}

.home_scroll-button:hover{
	transform: translateY(0.25rem);
}

.home_scroll-mouse{
	font-size: 2rem;
}

.home_scroll-name{
	font-size: var(--small-font-size);
	color: var(--title-color);
	font-weight: var(--font-medium);
	margin-right: var(--mb-0-25);
}

.home_scroll-arrow{
	font-size: 1.25rem;
}

/* Buttons Section */
.button{
	display: inline-block;
	background-color: var(--first-color);
	color: #fff;
	padding: 1rem;
	border-radius: .5rem;
	font-weight: var(--font-medium);
}

.button:hover{
	background-color: var(--first-color-alt);
}

.button_icon{
	font-size: 1.25rem;
	margin-left: var(--mb-0-5);
	transition: .3s;
}

.button--flex{
	display: inline-flex;
	align-items: center;
}

.button--small{
	padding: .75rem 1rem;
}

.button--link{
	padding: 0;
	background-color: transparent;
	color: var(--first-color);
}

.button--link:hover{
	background-color: transparent;
	color: var(--first-color-alt);
}

/* About Section */
.about_img{
	width: 200px;
	height: auto; /* Keeps the aspect ratio */
	border-radius: 10px; /* Optional, to round corners */
	/* Adds a subtle shadow */
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
	object-fit: cover; /* Ensures the image covers the defined dimensions */
	transition: transform 0.3s ease-in-out; /* Adds a smooth hover effect */
	justify-self: center;
	align-self: center;
}

.about_img:hover{
	transform: scale(1.05); /* Slight zoom on hover */
}


.about_description{
	text-align: center;
	margin-bottom: var(--mb-2-5);
}

.about_info{
	display: flex;
	justify-content: space-evenly;
	margin-bottom: var(--mb-2-5);
}

.about_info-title{
	font-size: var(--h2-font-size);
	font-weight: var(--font-semi-bold);
	color: var(--title-color);
}

.about_info-name{
	font-size: var(--smaller-font-size);
}

.about_info-title,
.about_info-name{
	display: block;
	text-align: center;
}

.about_buttons{
	display: flex;
	justify-content: center;
}


/* Youtube Section */
.youtube_img{
	width: 200px;
	height: auto; /* Keeps the aspect ratio */
	border-radius: 10px; /* Optional, to round corners */
	/* Adds a subtle shadow */
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
	object-fit: cover; /* Ensures the image covers the defined dimensions */
	transition: transform 0.3s ease-in-out; /* Adds a smooth hover effect */
}

.youtube_img:hover {
	transform: scale(1.05); /* Slight zoom on hover */
  }

.youtube_description{
	text-align: center;
	margin-bottom: var(--mb-2-5);
}

.youtube_info{
	display: flex;
	justify-content: space-evenly;
	margin-bottom: var(--mb-2-5);
}

.youtube_info-title{
	font-size: var(--h2-font-size);
	font-weight: var(--font-semi-bold);
	color: var(--title-color);
}

.youtube_info-name{
	font-size: var(--smaller-font-size);
}

.youtube_info-title,
.youtube_info-name{
	display: block;
	text-align: center;
}

.youtube_buttons{
	display: flex;
	justify-content: center;
}




/* Youtube Section */
/* .project_img{
	width: 200px;
	border-radius: .5rem;
	
	justify-self: center;
	align-self: center;
} */
.project_img {
	width: 200px;
	border-radius: 10px; /* Optional, to round corners */
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
	/* object-fit: cover;  */
	transition: transform 0.3s ease-in-out; /* Adds a smooth hover effect */
  }
  
  .project_img:hover {
	transform: scale(1.05); /* Slight zoom on hover */
  }

  
.project_description{
	text-align: center;
	margin-bottom: var(--mb-2-5);
}

.project_info{
	display: flex;
	justify-content: space-evenly;
	margin-bottom: var(--mb-2-5);
}

.project_info-title{
	font-size: var(--h2-font-size);
	font-weight: var(--font-semi-bold);
	color: var(--title-color);
}

.project_info-name{
	font-size: var(--smaller-font-size);
}

.project_info-title,
.project_info-name{
	display: block;
	text-align: center;
}

.project_buttons{
	display: flex;
	justify-content: center;
}





/* Skills Section */

.skills_container{
	row-gap: 0;
}

.skills_header{
	display: flex;
	align-items: center;
	margin-bottom: var(--mb-2-5);
	cursor: pointer;
}

.skills_icon,
.skills_arrow{
	font-size: 2rem;
	color: var(--first-color);
}

.skill_icon{
	margin-right: var(--mb-0-75);
}

.skills_title{
	font-size: var(--h3-font-size);
}

.skills_subtitle{
	font-size: var(--small-font-size);
	color: var(--text-color-light);
}

.skills_arrow{
	margin-left: auto;
	transition: .4s;
}

.skills_list{
	row-gap: 1.5rem;
	padding-left: 2.7rem;
}

.skills_titles{
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--mb-0-5);
}

.skills_name{
	font-size: var(--normal-font-size);
	font-weight: var(--font-medium);
}

.skills_bar,
.skills_percentage{
	height: 5px;
	border-radius: .25rem;
}

.skills_bar{
	background-color: var(--first-color-lighter);
}

.skills_percentage{
	display: block;
	background-color: var(--first-color);
}

/* Percentage bar Section */

.skills_html,
.skills_python,
.skills_firebase{
	width: 90%;
}

.skills_vuejs,
.skills_php,
.skills_pandas,
.skills_machine,
.skills_flutter{
	width: 80%;
}

.skills_css,
.skills_node{
	width: 70%;
}

.skills_javascript,
.skills_react,
.skills_reactNative{
	width: 60%;
}

.skills_close .skills_list{
	height: 0;
	overflow: hidden;
}

.skills_open .skills_list{
	height: max-content;
	margin-bottom: var(--mb-2-5);
}

.skills_open .skills_arrow{
	transform: rotate(-180deg);
}

/* Qualification Section */

.qualification_tabs{
	display: flex;
	justify-content: space-evenly;
	margin-bottom: var(--mb-2);
}

.qualication_button{
	font-size: var(--h3-font-size);
	font-weight: var(--font-medium);
	cursor: pointer;
}

.qualification_button:hover{
	color: var(--first-color);
	cursor: pointer;
}

.qualification_icon{
	font-weight: 1.8rem;
	margin-left: var(--mb-0-25);
}

.qualification_data{
	display: grid;
	grid-template-columns: 1fr max-content 1fr;
	column-gap: 1.5rem;
}

.qualification_title{
	font-size: var(--normal-font-size);
	font-weight: var(--font-medium);
}

.qualification_subtitle{
	display: inline-block;
	font-size: var(--small-font-size);
	margin-bottom: var(--mb-1);
}

.qualification_calendar{
	font-size: var(--small-font-size);
	color: var(--text-color-light);
}

.qualification_rounder{
	display: inline-block;
	width: 13px;
	height: 13px;
	background-color: var(--first-color);
	border-radius: 50%;
}

.qualification_line{
	display: block;
	width: 1px;
	height: 100%;
	background-color: var(--first-color);
	transform: translate(6px, -7px);
}

.qualification [data-content]{
	display: none;
}

.qualification_active[data-content]{
	display: block;
}

.qualification_button.qualification_active{
	color: var(--first-color);
}

/* Services Section */

.services_container{
	gap: 1.5rem;
	grid-template-columns: repeat(2, 1fr);
}

.services_content{
	position: relative;
	background-color: var(--container-color);
	padding: 3.5rem .5rem 1.25rem 1.5rem;
	border-radius: .25rem;
	box-shadow: 0 2px 4px rgba(0,0,0,.15);
	transition: .3s;
}

.services_content:hover{
	box-shadow: 0 4px 8px rgba(0,0,0,.15);
}

.services_icon{
	display: block;
	font-size: 1.5rem;
	color: var(--first-color);
	margin-bottom: var(--mb-1);
}

.services_title{
	font-size: var(--h3-font-size);
	margin-bottom: var(--mb-1);
	font-weight: var(--font-medium);
}

.services_button{
	cursor: pointer;
	font-size: var(--small-font-size);
}

.services_button:hover .button_icon{
	transform: translateX(.25rem);
}

.services_modal{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,.5);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 1rem;
	z-index: var(--z-modal);
	opacity: 0;
	visibility: hidden;
	transition: .3s;
}

.services_modal-content{
	position: relative;
	background-color: var(--container-color);
	padding: 1.5rem;
	border-radius: .5rem;
}

.services_modal-services{
	row-gap: 1rem;
}

.services_modal-service{
	display: flex;
}

.services_modal-title{
	font-size: var(--h3-font-size);
	font-weight: var(--font-medium);
	margin-bottom: var(--mb-1-5);
}

.services_modal-close{
	position: absolute;
	top: 1rem;
	right: 1rem;
	font-size: 1.5rem;
	color: var(--first-color);
	cursor: pointer;
}

.services_modal-icon{
	color: var(--first-color);
	margin-right: --b=var(--mb-0-25);
}

/* Active Modal */
.active-modal{
	opacity: 1;
	visibility: visible;
}

/* Portfolio Section */

.portfolio_container{
	overflow: initial;
}

.portfolio_content{
	padding: 0 1.5rem;
}

.portfolio_img{
	width: 265px;
	border-radius: .5rem;
	justify-self: center;
}

.portfolio_title{
	font-size: var(--h3-font-size);
	margin-bottom: var(--mb-0-5);
}

.portfolio_description{
	margin-bottom: var(--mb-0-75);
}

.portfolio_button:hover .button_icon{
	transform: translateX(.25rem);
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
  }

.swiper-button-prev::after,
.swiper-button-next::after{
	content: '';
}

.swiper-portfolio-icon{
	font-size: 2rem;
	color: var(--first-color);
}

.swiper-button-prev{
	left: -.5rem;
}

.swiper-button-next{
	right: -.5rem;
}

.swipper-container-horizontal > .swiper-pagination-bullets{
	bottom: -3rem;
}

.swiper-pagination-bullet-active{
	background-color: var(--first-color);
}

.swiper-button-prev,
.swiper-button-next,
.swiper-pagination-bullet{
	outline: none;
}

/* Project Section */
.project{
	text-align: center;
}

.project_bg{
	background-color: var(--first-color-second);
	padding-top: 3rem;
}

.project_title{
	font-size: var(--h2-font-size);
	margin-bottom: var(--mb-0-75);
}

.project_description{
	margin-bottom: var(--mb-1-5);
}

.project_title,
.project_description{
	color: #fff;
}

.project_img{
	width: 232px;
	transform: translateY(12px);
	justify-content: center;
}

/* Certificates Section */

.certificate_data,
.certificate_header{
	display: flex;
}

.certificate_data{
	justify-content: space-between;
	margin-bottom: var(--mb-1);
}

.certificate_img{
	width: 100%;
	/* border-radius: .2rem; */
	justify-self: center;
}

.certificate_name{
	font-size: var(--h3-font-size);
	font-weight: var(--font-medium);
}

.certificate_platform{
	font-size: var(--small-font-size);
	color: var(--text-color-light);
}

.certificate_description{
	margin-bottom: var(--mb-0-25);
}

.certificate_section .swiper-container .swiper-pagination-certificate{
	bottom: 0;
}

/* Contact Section */

.contact_container{
	row-gap: 3rem;
}

.contact_information{
	display: flex;
	margin-bottom: var(--mb-2);

}

.contact_icon{
	font-size: 2rem;
	color: var(--first-color);
	margin-right: var(--mb-0-75);
}

.contact_title{
	font-size: var(--h3-font-size);
	font-weight: var(--font-medium);
}

.contact_subtitle{
	font-size: var(--small-font-size);
	color: var(--text-color-light);
}

.contact_content{
	background-color: var(--input-color);
	border-radius: .5rem;
	padding: .75rem 1rem .25rem;
}

.contact_label{
	font-size: var(--smaller-font-size);
	color: var(--title-color);
}

.contact_input{
	width: 100%;
	background-color: var(--input-color);
	color: var(--text-color);
	font-family: var(--body-font);
	font-size: var(--normal-font-size);
	border: none;
	outline: none;
	padding: .25rem .5rem .5rem 0;
}
.button--contact{
	background-color: var(--first-color);
	border: none;
	color: white;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	cursor: pointer;
	
}



/* Footer Section */
.footer{
	padding-top: 2rem;
}

.footer_container{
	row-gap: 3.5rem;
}

.footer_bg{
	background-color: var(--first-color-second);
	padding: 2rem 0 3rem;
}

.footer_title{
	font-size: var(--h1-font-size);
	margin-bottom: var(--mb-0-25);
}

.footer_subtitle{
	font-size: var(--small-font-size);
}

.footer_links{
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
}

.footer_link:hover{
	color: var(--first-color-lighter);
}

.footer_social{
	font-size: 1.25rem;
	margin-right: var(--mb-1-5);
}

.footer_social:hover{
	color: var(--first-color-lighter);
}

.footer_copy{
	font-size: var(--small-font-size);
	text-align: center;
	color: var(--text-color-light);
	margin-top: var(--mb-3);
}

.footer_title,
.footer_subtitle,
.footer_link,
.footer_social{
	color: #fff;
}

/* Scroll Up Section */

.scrollup{
	position: fixed;
	right: 1rem;
	bottom: -20%;
	background-color: var(--first-color);
	opacity: .8;
	padding: 0 .3rem;
	border-radius: .4rem;
	z-index: var(--z-tooltip);
	transition: .4s;
}

.scrollup:hover{
	background-color: var(--first-color-alt);
}

.scrollup_icon{
	font-size: 1.5rem;
	color: #fff;
}

/* show scroll */
.show-scroll{
	bottom: 5rem;
}

/* Scroll Bar Section */

::-webkit-scrollbar{
	width: .60rem;
	background-color: var(--scroll-bar-color);
	border-radius: .5rem;
}

::-webkit-scrollbar-thumb{
	background-color: var(--scroll-thumb-color);
	border-radius: .5rem;
}

::-webkit-scrollbar-thumb:hover{
	background-color: var(--text-color-light);
}

/* ProgressBar Section */
.progressContainer{
	left: 0;
	width: 100%;
	height: 0.4em;
	margin-bottom: 0px;
	position: fixed;
	bottom: 0;
	overflow: hidden;
	background-color: var(--scroll-thumb-color);
	content: "";
	display: table;
	table-layout: fixed;
	z-index: 9999;
}

.progressBar{
	width: 0%;
	float: left;
	height: 100%;
	z-index: 9999;
	max-width: 100%;
	background-color: var(--progress-color);
	-webkit-transition: width .6s ease;
	-o-transition: width .6s case;
	transition: width .6s case;
}


/* Media Queries Section */
/* Small devices */
@media screen and (max-width: 350px){
	.container{
		margin-left: var(--mb-1);
		margin-right: var(--mb-1);
	}

	.nav_menu{
		padding: 2rem .25rem 4rem;
	}
	.nav_list{
		column-gap: 0;
	}

	.home_content{
		grid-template-columns: .25fr 3fr;
	}
	.home_blob{
		width: 180px;
	}

	.skills_title{
		font-size: var(--normal-font-size);
	}

	.qualification_data{
		gap: .5rem;
	}

	.services_container{
		grid-template-columns: max-content;
		justify-content: center;
	}

	.services_content{
		padding-right: 3.5rem;
	}
	.services_modal{
		padding: 0 .5rem;
	}

	.project_img{
		width: 200px;
	}

	.certificate_data,
	.certificate_header{
		flex-direction: column;
		align-items: center;
	}
	.certificate_img{
		margin-right: 0;
		margin-bottom: var(--mb-0-25);
	}
	.certificate_data,
	.certificate_description{
		text-align: center;
	}
}

/* Medium devices */
@media screen and (min-width: 568px){
	.home_content{
		grid-template-columns: max-content 1fr 1fr;
	}
	.home_data{
		grid-column: initial;
	}
	.home_img{
		order: 1;
		justify-content: center;
		object-fit: cover;
		transition: transform 0.3s ease-in-out; /* Adds a smooth hover effect */
	}

	.home_img:hover{
		transform: scale(1.05); /* Slight zoom on hover */

	}

	.about_container,
	.skills_container,
	.portfolio_content,
	.project_container,
	.contact_container,
	.footer_container{
		grid-template-columns: repeat(2, 1fr);
	}

	.button--small{
		padding: .30rem 1rem;
	}

	.qualification_sections{
		display: grid;
		grid-template-columns: .6fr;
		justify-content: center;
	}
}

@media screen and (min-width: 768px){
	.container{
		margin-left: auto;
		margin-right: auto;
	}

	body{
		margin: 0;
	}

	.section{
		padding: 6rem 0 2rem;
	}
	.section_subtitle{
		margin-bottom: 4rem;
	}

	.header{
		top: 0;
		bottom: initial;
	}

	.header,
	.main,
	.footer_container{
		padding: 0 1rem;
	}

	.nav{
		height: calc(var(--header-height) + 1.5rem);
		column-gap: 1rem;
	}
	.nav_icon,
	.nav_close,
	.nav_toggle{
		display: none;
	}
	.nav_list{
		display: flex;
		column-gap: 2rem;
	}
	.nav_menu{
		margin-left: auto;
	}
	.change-theme{
		margin: 0;
	}

	.home_container{
		row-gap: 5rem;
	}
	.home_content{
		padding-top: 5.5rem;
		column-gap: 2rem;
	}
	.home_blob{
		width: 270px;
	}
	.home_scroll{
		display: block;
	}
	.home_section-button{
		margin-left: 3rem;
	}

	.about_container{
		column-gap: 5rem;
	}
	.about_img{
		width: 350px;
	}
	.about_description{
		text-align: initial;
	}
	.about_info{
		justify-content: space-between;
	}
	.about_buttons{
		justify-content: initial;
	}

	.qualification_tabs{
		justify-content: center;
	}
	.qualication_button{
		margin: 0 var(--mb-1);
	}
	.qualification_sections{
		grid-template-columns: .5fr;
	}

	.services_container{
		grid-template-columns: repeat(3, 218px);
		justify-content: center;
	}
	.services_icon{
		font-size: 2rem;
	}
	.services_content{
		padding: 6rem 0 2rem 2.5rem;
	}
	.services_modal-content{
		width: 450px;
	}

	.portfolio_img{
		width: 320px;
	}
	.portfolio_content{
		align-items: center;
	}

	.project{
		text-align: initial;
	}
	.project_bg{
		background: none;
	}
	.project_container{
		background-color: var(--first-color-second);
		border-radius: 1rem;
		padding: 3rem 2.5rem 0;
		grid-template-columns: 1fr max-content;
		column-gap: 3rem;
	}
	.project_data{
		padding-top: .8rem;
	}

	.footer_container{
		grid-template-columns: repeat(3, 1fr);
	}
	.footer_bg{
		padding: 3rem 0 3.5rem;
	}
	.footer_links{
		flex-direction: row;
		column-gap: 2rem;
	}
	.footer_socials{
		justify-self: flex-end;
	}
	.footer_copy{
		margin-top: 4.5rem;
	}
}


/* Large devices */
@media screen and (min-width: 1024px){
	.header,
	.main,
	.footer_container{
		padding: 0;
	}

	.home_blob{
		width: 320px;
	}
	.home_social{
		transform: translateX(-3.5rem);
	}

	.services_container{
		grid-template-columns: repeat(3, 238px);
	}

	.portfolio_content{
		column-gap: 5rem;
	}
	.swiper-portfolio-icon{
		font-size: 3.5rem;
	}
	.swiper-button-prev{
		left: -3.5rem;
	}
	.swiper-button-next{
		right: -3.5rem;
	}
	.swipper-container-horizontal > .swiper-pagination-bullets{
		bottom: -4.5rem;
	}

	.contact_form{
		width: 460px;
	}
	.contact_inputs{
		grid-template-columns: repeat(1, 1fr);
	}
}
